// src/App.tsx
import React from 'react';
import Header from './Header';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo'; // Import SectionTwo
import bgImage1 from './ASSETS/BG1.png';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="app-container">
      <Header />
      <div className="background-image" style={{ backgroundImage: `url(${bgImage1})` }} />
      <div className="main-content">
        <SectionOne />
        <SectionTwo /> {/* Add SectionTwo */}
        {/* Other content here */}
      </div>
    </div>
  );
};

export default App;

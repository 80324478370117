// src/Header.tsx
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Twitter } from '@mui/icons-material'; // Import the Twitter icon
import './Header.css';

const Header: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState<string>('');

  useEffect(() => {
    const targetDate = moment('2024-06-13 22:00:00');

    const updateCountdown = () => {
      const now = moment();
      const duration = moment.duration(targetDate.diff(now));
      const formattedTime = `${Math.floor(duration.asDays())}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
      setTimeLeft(formattedTime);
    };

    updateCountdown();
    const timer = setInterval(updateCountdown, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleTwitterClick = () => {
    window.open('https://x.com/duangworld', '_blank', 'noopener,noreferrer');
  };

  return (
    <header className="header">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="countdown">{timeLeft}</div>
        <button className="x-logo-button" onClick={handleTwitterClick}>
          <Twitter style={{ color: 'white', width: '24px', height: '24px' }} />
        </button>
      </div>
    </header>
  );
};

export default Header;

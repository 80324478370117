// src/SectionTwo.tsx
import React from 'react';
import bgImage3 from './ASSETS/BG3.jpeg';
import './SectionTwo.css';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Airdrop', value: 5 },
  { name: 'Mobile Pool', value: 10 },
  { name: 'DUANG World Bank', value: 20 },
  { name: 'Community', value: 25 },
  { name: 'Fair Launch', value: 40 },
];

const COLORS = ['#67a3ad', '#f9f2e3', '#ee6d45', '#d0c3a9', '#3a6d7f'];

const RADIAN = Math.PI / 180;

interface LabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: LabelProps) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}% ${data[index].name}`}
    </text>
  );
};

const SectionTwo: React.FC = () => {
  return (
    <div className="section-two" style={{ backgroundImage: `url(${bgImage3})` }}>
      <div className="section-two-content">
        <h2>Token Distribution</h2>
        <div className="chart-container">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={200} // Increase the outerRadius value
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};


export default SectionTwo;
// src/SectionOne.tsx
import React from 'react';
import bgImage2 from './ASSETS/BG2.png';
import './SectionOne.css';

const SectionOne: React.FC = () => {
  return (
    <div className="section-one" style={{ backgroundImage: `url(${bgImage2})` }}>
      <div className="section-one-content">
        <h2>Introduction</h2>
        <p>DUANG is a standalone virtual digital world with its own independent economic system, people, land, space, and everything else.</p>
        <p>It coexists with the real world and we call it the "Second World" project. It's linked to the real world without interfering, yet they are interconnected.</p>
        <hr />
        <h2>Duang Development Plan</h2>
        <div className="bullet-points">
          <div className="row">
            <div className="point">
              <p>1. What is a point?</p>
              <div className="sub-points">
                <p>- Single Element</p>
                <p>- Elements will synthesize larger elements</p>
                <p>- Elements make up points</p>
              </div>
            </div>
            <div className="point">
              <p>2. Fragmented Era</p>
              <div className="sub-points">
                <p>- Dots will form fragments</p>
                <p>- Fragments can be stacked, spliced, and devoured</p>
                <p>- Fragments can interact with each other</p>
                <p>- The formation of things (fragments form things)</p>
              </div>
            </div>
            <div className="point">
              <p>3. DUANG</p>
              <div className="sub-points">
                <p>- The composition of cities</p>
                <p>- Explanation of Bubble Balls</p>
                <p>- $DUANG tokens and bubble balls</p>
                <p>- How to enter DUANG</p>
                <p>- DUANG’s Economics</p>
                <p>- The Interoperability of DUANG World</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="point">
              <p>4. The Fission of Networks</p>
              <div className="sub-points">
                <p>- Unidirectional Network</p>
                <p>- The formation of independent networks</p>
                <p>- The formation of recurrent networks</p>
              </div>
            </div>
            <div className="point">
              <p>5. Stable DUANG</p>
              <div className="sub-points">
                <p>- Interactive DUANG</p>
                <p>- Fully independent and autonomous DUANG</p>
              </div>
            </div>
            <div className="point">
              <p>6. Tokens and Economics</p>
              <div className="sub-points">
                <p>- 100,000,000 $DUANG Tokens</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
